import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import classes from './Layout.module.scss'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import { ConfigContext } from '../store/ConfigContext'
import Sidebar from './Sidebar'
import { useLocalStorage } from '../hooks/useLocalStorage'
import FooterNavigation from './FooterNavigation'
import { ContentStackContext } from '@jarvis/olex-content-management'
import { useContentStackLivePreview } from '../hooks/useContentStackLivePreview'
import { ContentStackTypes, Paths, PrinterFamily } from '../store/Constants'
import { LayoutDefinition } from './LayoutDefinition'
import { FeatureFlagsContext } from '../store/FeatureFlagsContext'
import { useHpPlusClient } from '../hooks/useHpPlusClient'
import FooterCopyright from './FooterCopyright'
import ShortFlow from './ShortFlow'

const Layout = ({ children }) => {
  const {
    states: { footer: footerState, sidebar: sidebarState },
    sku,
    printer,
    paas,
    footer,
    pageDataCache,
    header,
    sidebar,
    os,
    isOsSupported,
    showInstallButton,
    isBrowserSupported,
    contentStackCredential,
    currentPath,
    isMobile,
    compatibilityDefinition,
    error,
    setError,
    isWeChat,
    subTabNavigation,
    printerFamily
  } = useContext(ConfigContext)

  const { flags } = useContext(FeatureFlagsContext)

  const { client, locale, livePreviewInfo } = useContext(ContentStackContext)
  const [livePreviewEntry, setLivePreviewEntry] = useState(null)
  const { runRedirect } = useContentStackLivePreview()
  const { isLoaded, resetStorageStatus } = useLocalStorage()
  const { isHpPlus, isHpPlusCountry } = useHpPlusClient()

  const sharedContext = {
    sku,
    printer,
    paas,
    pageDataCache,
    footer,
    header,
    sidebar,
    os,
    isOsSupported,
    showInstallButton,
    isBrowserSupported,
    contentStackCredential,
    currentPath,
    isHpPlus,
    isHpPlusCountry,
    isMobile,
    compatibilityDefinition,
    error,
    setError,
    flags,
    isWeChat,
    subTabNavigation,
    printerFamily,
    resetStorageStatus
  }

  useEffect(() => {
    const pageData = pageDataCache?.get(
      ContentStackTypes.tde_layout.content_type_uid
    )
    if (pageData) {
      if (!footerState?.nextButtonLabel) {
        footer.setNextButtonLabel(pageData.footer?.next_button)
      }
      if (!footerState?.backButtonLabel) {
        footer.setBackButtonLabel(pageData.footer?.back_button)
      }
    }
  }, [pageDataCache, footer, footerState, sidebarState])

  useEffect(() => {
    if (livePreviewInfo && livePreviewInfo?.contentTypeUid) {
      client
        .ContentType(livePreviewInfo?.contentTypeUid)
        .Entry(livePreviewInfo?.entryUid)
        .language(
          `${locale.language.toLowerCase()}-${locale.country.toLowerCase()}`
        )
        .includeFallback()
        .toJSON()
        .fetch()
        .then((response) => {
          setLivePreviewEntry(response?.[0]?.[0] || response)
        })
    }
  }, [livePreviewInfo, livePreviewInfo?.contentTypeUid])

  useEffect(() => {
    if (isLoaded && livePreviewInfo?.contentTypeUid && livePreviewEntry) {
      runRedirect({
        livePreviewEntry
      })
    }
  }, [isLoaded, livePreviewInfo?.contentTypeUid, livePreviewEntry])

  if ([Paths.not_found, Paths.search, Paths.install].includes(currentPath)) {
    return (
      <LayoutDefinition>
        {children ? (
          children(sharedContext)
        ) : (
          <ProgressIndicator
            data-testid="progress"
            appearance="linear"
            behavior="indeterminate"
          />
        )}
      </LayoutDefinition>
    )
  }

  if (!isLoaded) {
    return (
      <div className={classes.progressBackground}>
        <ProgressIndicator
          data-testid="progress"
          appearance="linear"
          behavior="indeterminate"
        />
      </div>
    )
  }

  if (
    [
      PrinterFamily.MANHATTAN_BASE,
      PrinterFamily.MANHATTAN_HI,
      PrinterFamily.MALBEC,
      PrinterFamily.NOVELLI,
      PrinterFamily.SAYAN,
      PrinterFamily.TACCOLA
    ].includes(printerFamily)
  ) {
    return (
      <LayoutDefinition
        layout="shortFlow"
        header={<Header />}
        sidebar={<Sidebar />}
        footer={<FooterCopyright />}
      >
        {children ? (
          <ShortFlow>{children(sharedContext)}</ShortFlow>
        ) : (
          <ProgressIndicator
            data-testid="progress"
            appearance="linear"
            behavior="indeterminate"
          />
        )}
      </LayoutDefinition>
    )
  }

  return (
    <LayoutDefinition
      header={<Header />}
      sidebar={<Sidebar />}
      footer={
        header.selectedTab === 0 && currentPath !== `/${sku}` ? (
          <FooterNavigation />
        ) : (
          <FooterCopyright />
        )
      }
    >
      {children ? (
        children(sharedContext)
      ) : (
        <ProgressIndicator
          data-testid="progress"
          appearance="linear"
          behavior="indeterminate"
        />
      )}
    </LayoutDefinition>
  )
}

export default Layout
