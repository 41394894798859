// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S5y94qVnUoE7Qvm73zgD{display:flex;flex-direction:column;gap:8px}.S5y94qVnUoE7Qvm73zgD>button{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/components/ShortFlow.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,6BACC,qBAAA","sourcesContent":[".shortFlow {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 8px;\n\n\t> button {\n\t\talign-self: flex-start;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shortFlow": "S5y94qVnUoE7Qvm73zgD"
};
export default ___CSS_LOADER_EXPORT___;
