import React, { useContext, useEffect, useMemo, useState } from 'react'
import IconMenu from '@veneer/core/dist/esm/scripts/icons/icon_menu'
import LogoHp from '@veneer/core/dist/esm/scripts/logos/hp/hp'
import AppHeader from '@veneer/core/dist/esm/scripts/app_header/app_header'
import SideMenu from '@veneer/core/dist/esm/scripts/side_menu'
import SideMenuItem from '@veneer/core/dist/esm/scripts/side_menu_item'
import VeneerHeader from '@veneer/core/dist/esm/scripts/header'
import classes from './Header.module.scss'
import { ConfigContext } from '../store/ConfigContext'
import { createPortal } from 'react-dom'
import { ContentStackContext } from '@jarvis/olex-content-management'
import { Paths } from '../store/Constants'
import useAnalytics from '../hooks/useAnalytics'
import { ANALYTICS } from '../store/AnalyticsConstants'
import { getActionAuxParamsForAnalytics } from '../utils/Utils'

const Header = () => {
  const {
    sku,
    paas,
    printer,
    header,
    navigation,
    setSelectedTab,
    setPreviousSelectedTab,
    localization,
    os,
    isFlowers,
    isInstallRedirectURL,
    subTabNavigation
  } = useContext(ConfigContext)
  const { livePreviewInfo } = useContext(ContentStackContext)
  const { fireButtonClick, fireCustomScreenDisplayed } = useAnalytics(
    ANALYTICS.SCREENS.HEADER_NAVIGATION
  )
  const [showSideMenu, setShowSideMenu] = useState(false)
  const sideMenuNode = document.getElementById('side-menu-node')

  const isNotFoundPage = navigation.location.pathname === Paths.not_found

  const handleMenuClick = (tabId, subtabId) => {
    setPreviousSelectedTab(header.selectedTab)
    setSelectedTab(tabId)
    header.setSelectedTabItem(tabId, subtabId)
    setShowSideMenu(false)
  }

  const handleLogoClick = () => {
    if (isNotFoundPage) {
      return
    }

    fireButtonClick(ANALYTICS.BUTTONS.HOMEPAGE_HP_LOGO, {
      detail: window.location.pathname,
      actionAuxParams: getActionAuxParamsForAnalytics(
        sku,
        paas,
        localization.country,
        localization.language,
        os
      )
    })
    setSelectedTab(0)
    navigation.push(`/${sku}`)
  }

  const headerModelName = useMemo(() => {
    if (livePreviewInfo?.contentTypeUid) {
      return 'LIVE PREVIEW MODE'
    }

    return isNotFoundPage ? '' : printer?.productShortName
  }, [
    isNotFoundPage,
    livePreviewInfo?.contentTypeUid,
    printer?.printerShortName
  ])

  const Logo = ({ size = 32, id = '' }) => {
    return (
      <LogoHp
        data-testid={`hp-logo${id}`}
        className={classes.logo}
        size={size}
        appearance="singlecolor"
      />
    )
  }

  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu)
  }

  const getSideMenuContent = () => {
    return (
      <div className={classes.sideMenu} data-testid="sidemenu">
        <div className={classes.sideMenuHeader}>
          <Logo size={24} id={'sidemenu'} />
          <div>{headerModelName}</div>
        </div>
        <div className={classes.sideMenuContent} data-testid="sidemenu-content">
          {subTabNavigation?.map((option) => {
            return (
              <SideMenuItem
                expanded
                key={option.id}
                label={option.label}
                className={classes.sideMenuItem}
                onClick={
                  option.sub_options.length === 0
                    ? () => handleMenuClick(option.id, 0)
                    : null
                }
              >
                {option.sub_options.length > 0 &&
                  option.sub_options.map((subtab) => {
                    return (
                      <SideMenuItem
                        key={subtab.id}
                        label={subtab.label}
                        className={classes.submenu}
                        selected={
                          option.id === header.selectedTab &&
                          subtab.id ===
                            header.selectedTabItem[header.selectedTab]
                        }
                        onClick={() => handleMenuClick(option.id, subtab.id)}
                      />
                    )
                  })}
              </SideMenuItem>
            )
          })}
        </div>
      </div>
    )
  }

  useEffect(() => {
    fireCustomScreenDisplayed(ANALYTICS.SCREEN_BY_SUBTAB[header.selectedTab])
  }, [header.selectedTab])

  return (
    <React.Fragment>
      {!isNotFoundPage &&
        sideMenuNode &&
        createPortal(
          <SideMenu
            className={classes.sideMenu}
            onToggle={toggleSideMenu}
            expanded={showSideMenu}
            defaultExpanded={false}
            hideAppHeader={true}
            responsiveBreakpoint={'1007px'}
          >
            {getSideMenuContent()}
          </SideMenu>,
          sideMenuNode
        )}
      {!isNotFoundPage && (
        <VeneerHeader
          className={classes.header}
          position="relative"
          appearance="dropShadow"
          leadingArea={
            isInstallRedirectURL ? (
              <AppHeader
                className={classes.appHeader}
                appName={headerModelName}
                logo={<Logo />}
                disableResponsive={true}
              />
            ) : (
              <a
                onClick={handleLogoClick}
                className={classes.link}
                data-testid={'homepage-logo'}
              >
                <AppHeader
                  className={classes.appHeader}
                  appName={headerModelName}
                  logo={<Logo />}
                  disableResponsive={true}
                />
              </a>
            )
          }
          trailingArea={
            !isInstallRedirectURL &&
            !isNotFoundPage &&
            !isFlowers && (
              <IconMenu
                data-testid="hamburger-menu"
                className={classes.menuIcon}
                onClick={toggleSideMenu}
                size={24}
              />
            )
          }
        />
      )}
      {subTabNavigation &&
        subTabNavigation.map((tab) => (
          <div key={tab.id} id={tab.label?.toLowerCase()} />
        ))}
    </React.Fragment>
  )
}

export default Header
