import React from 'react'
import classes from './Sidebar123Layout.module.scss'
import Button from '@veneer/core/dist/esm/scripts/button'

const Sidebar123Layout = ({ printerImage, printerName }) => {
  return (
    <div className={classes.sidebar} data-testid="123-sidebar">
      <img src={printerImage} alt={printerName} />
      <b>{printerName}</b>
      <div className={classes.spaceBetween} />
      <Button>Language Selector</Button>
    </div>
  )
}

export default Sidebar123Layout
