import { PrinterFamily } from './Constants'
import { printerFamilyToDerivativeName } from '../utils/Utils'

export const MockedPLSConfig = {
  enabled: false,
  hpPlus: true,
  paas: true,
  printerFamily: PrinterFamily.MANHATTAN_BASE,
  printerName: 'HP OfficeJet Pro 9120 All-in-One Series'
}

export const MockedPLS = () => ({
  benefitsControlModel: 'rightToPrint',
  bleHPSpecVersion: '4',
  cdmPubSubVersion: '2.0',
  codeName: 'Marconi',
  colorSupported: 'color',
  connectivityFamily: ['duneMMK'],
  countries: ['IL'],
  deviceCapabilities: [
    {
      name: 'wifi',
      support: 'true'
    },
    {
      name: 'wifiDirect',
      support: 'true'
    },
    {
      name: 'scan',
      support: 'true'
    },
    {
      name: 'fax',
      support: 'true'
    },
    {
      name: 'ethernet',
      support: 'true'
    },
    {
      name: 'ble',
      support: 'true'
    }
  ],
  deviceFullName: 'HP OfficeJet Pro 9120 All-in-One Series',
  deviceSegment: 'SMB',
  derivativeName: printerFamilyToDerivativeName(MockedPLSConfig.printerFamily),
  displayProfile: 'class-3',
  eprintSupported: false,
  helpPageUrl: 'https://www.hpsmartstage.com/support/printer-connection/403X0A',
  images: [
    'https://devices.us1.api.ws-hp.com/devices/v1/images/cee738fb5d0e32ff9e44fd4479a3efe1/300x245.png',
    'https://devices.us1.api.ws-hp.com/devices/v1/images/cee738fb5d0e32ff9e44fd4479a3efe1/170x128.png',
    'https://devices.us1.api.ws-hp.com/devices/v1/images/cee738fb5d0e32ff9e44fd4479a3efe1/130x102.png',
    'https://devices.us1.api.ws-hp.com/devices/v1/images/cee738fb5d0e32ff9e44fd4479a3efe1/42x26.png'
  ],
  infoOwner: 'Chris Indra',
  lastUpdatedTime: 1724045211,
  launchDate: '01/01/2024',
  makeAndModel: 'HP OfficeJet Pro 9123 All-in-One Printer',
  mfgBizModel: 'Flex',
  modelName: 'HP OfficeJet Pro 9120 All-in-One series',
  modelNumber: '403V9B',
  offer: 1,
  oid: '2101420662',
  onboardingCapabilities: {
    prohibitedClients: ['portalEnterprise', 'weChat', 'portalPrintOS'],
    allowedClients: [
      'smartWin',
      'smallville',
      'smartAndroid',
      'smartiOS',
      'smartMac',
      'portalConsumer',
      MockedPLSConfig.paas ? '' : 'portalSMB'
    ]
  },
  platformIdentifier: 'gen2Yeti',
  printContractVersion: '0.83',
  printOnTheGoSupport: 'pullRelease',
  printerSeriesOid: '2101610320',
  productFamily: 'officejet',
  productShortName: MockedPLSConfig.printerName,
  programs: MockedPLSConfig.hpPlus ? ['hp+'] : ['ucde'],
  pushScanSupported: 'true',
  releaseStatus: 'future',
  remoteAttestationSupport: 'true',
  resetPageUrl:
    'https://www.hpsmartstage.com/support/printer-connection/403X0A',
  seriesName: 'HP OfficeJet Pro 9120  Series',
  signalingProfile: 'default',
  sipsSupported: false,
  solutions: [
    'printAlwaysWorks',
    'smartSecurityEssential',
    'scanDestinations',
    'shortcuts',
    'photo',
    'mobileFax',
    'SmartSecurityRestore',
    'scanToHPCloud',
    'personalShortcuts',
    'instantSupplies',
    'officeProductivity',
    'printOnTheGo',
    'shortcutsOffice',
    'smartDashboard'
  ],
  supplyType: 'ink',
  supplies: [
    {
      colorCode: 'Y',
      slot: 3
    },
    {
      colorCode: 'C',
      slot: 1
    },
    {
      colorCode: 'K',
      slot: 4
    },
    {
      colorCode: 'M',
      slot: 2
    }
  ],
  supportedDataModels: ['IPP', 'eSCL', 'snmp', 'cdm', 'ws*'],
  wechatJpegDirectPrintEnabled: 'false'
})
