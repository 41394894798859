import { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { ContentStackContext } from '@jarvis/olex-content-management'
import { ContentTypePathDefinition } from '../store/Constants'

export const useContentStackLivePreview = () => {
  const { setSelectedTab, navigation } = useContext(ConfigContext)
  const { livePreviewInfo } = useContext(ContentStackContext)
  const currentPath = navigation?.location?.pathname

  const runRedirect = ({ livePreviewEntry }) => {
    const contentTypeInfo =
      ContentTypePathDefinition?.[livePreviewInfo?.contentTypeUid]

    if (contentTypeInfo) {
      const { defaultRedirect, types } = contentTypeInfo || {}
      const typePath = types?.[livePreviewEntry?.type]
      if (types && typePath) {
        if (typePath !== currentPath) {
          window.location.assign(typePath)
        }
        return
      }
      if (defaultRedirect !== currentPath) {
        window.location.assign(defaultRedirect)
      }
    }
    if (livePreviewInfo.contentTypeUid === 'subtab_layout') {
      if (livePreviewEntry.title.toUpperCase().startsWith('LEARN')) {
        setSelectedTab(1)
      }
      if (livePreviewEntry.title.toUpperCase().startsWith('GET HELP')) {
        setSelectedTab(2)
      }
    }
  }

  return {
    runRedirect
  }
}
