import { useContext, useMemo } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  FeatureFlagKeys
} from '../store/Constants'

export const useHpPlusClient = () => {
  const { pageDataCache, printer, localization, flags } =
    useContext(ConfigContext)

  const hpPlusCountries = useMemo(() => {
    return JSON.parse(
      (
        pageDataCache?.get(
          ContentStackTypes.configuration_definitions.content_type_uid
        ) || new Map()
      )?.definitions?.find(
        (definition) =>
          definition.configuration_key ===
          ConfigurationDefinitionsKeys.hpPlusCountries
      )?.configuration_value || '[]'
    )
  }, [pageDataCache])

  const hpCountriesInLowerCase = (hpPlusCountries || []).map((country) =>
    country.toLowerCase()
  )

  const ffIneligibleCountriesInLowerCase = (
    flags?.[FeatureFlagKeys.hpPlusIneligibleCountries] || []
  ).map((country) => country.toLowerCase())

  return {
    isHpPlus: printer?.programs?.includes('hp+'),
    isHpPlusCountry:
      hpCountriesInLowerCase.includes(localization.country.toLowerCase()) &&
      !ffIneligibleCountriesInLowerCase.includes(
        localization.country.toLowerCase()
      )
  }
}
