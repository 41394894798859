// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UGnBjomdsm78GQ8KTM2b{display:flex;flex-direction:column;gap:8px;height:calc(100vh - var(--footer-height) - var(--header-height))}.CutG1eWM8QiRO3y0L5Gu{flex-grow:1;width:100%}", "",{"version":3,"sources":["webpack://./src/components/Sidebar123Layout.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,qBAAA,CACA,OAAA,CACA,gEAAA,CAGD,sBACC,WAAA,CACA,UAAA","sourcesContent":[".sidebar {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 8px;\n\theight: calc(100vh - var(--footer-height) - var(--header-height));\n}\n\n.spaceBetween {\n\tflex-grow: 1;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "UGnBjomdsm78GQ8KTM2b",
	"spaceBetween": "CutG1eWM8QiRO3y0L5Gu"
};
export default ___CSS_LOADER_EXPORT___;
