import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import {
  ConfigurationDefinitionsKeys,
  ContentStackTypes,
  FeatureFlagKeys,
  SkuResolverStatus
} from '../store/Constants'
import { getConfigurationDefinition } from '../utils/Utils'
import OneTwoThreeClient from '../clients/OneTwoThreeClient'

export const useSkuResolver = (path) => {
  const [status, setStatus] = useState(SkuResolverStatus.PROCESSING)
  const { stack, pageDataCache, flags } = useContext(ConfigContext)

  const [resolvedSku, setResolvedSku] = useState(null)
  const oneTwoThreeClient = useRef(null)
  const { [FeatureFlagKeys.newLandingPage]: newLandingPageFlag } = flags || {}
  const [resolvedPrinterNameSku, setResolvedPrinterNameSku] = useState(null)
  const [resolvedPrinterNameStatus, setResolvedPrinterNameStatus] = useState(
    SkuResolverStatus.PROCESSING
  )

  useEffect(() => {
    if (resolvedPrinterNameStatus !== SkuResolverStatus.PROCESSING) {
      return
    }
    if (
      newLandingPageFlag === true &&
      path.toLowerCase().includes('-') &&
      path.toLowerCase() !== 'not-found'
    ) {
      if (oneTwoThreeClient.current === null) {
        oneTwoThreeClient.current = new OneTwoThreeClient(stack)
      }
      const controller = new AbortController()
      oneTwoThreeClient.current
        .getDeviceByName(path.toLowerCase())
        .then((response) => {
          if (response.data?.skus?.length > 0) {
            setResolvedPrinterNameSku(response.data.skus[0])
          }
          setResolvedPrinterNameStatus(SkuResolverStatus.COMPLETED)
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error(
              'Device not found while resolving printer name on 123:',
              path,
              error.response.status
            )
          } else {
            console.error(
              'An error occurred while resolving printer name on 123:',
              path,
              error
            )
          }
          setResolvedPrinterNameStatus(SkuResolverStatus.ERROR)
        })
      return () => controller.abort()
    } else if (
      newLandingPageFlag === undefined ||
      newLandingPageFlag === null
    ) {
      return () => {}
    } else {
      setResolvedPrinterNameStatus(SkuResolverStatus.COMPLETED)
    }
  }, [newLandingPageFlag, path, stack])

  const configurationDefinition = useMemo(
    () =>
      pageDataCache?.get(
        ContentStackTypes.configuration_definitions.content_type_uid
      ),
    [pageDataCache]
  )

  useEffect(() => {
    if (
      !configurationDefinition ||
      resolvedPrinterNameStatus === SkuResolverStatus.PROCESSING ||
      status === SkuResolverStatus.COMPLETED
    ) {
      return
    }

    const friendlyUrlMap = getConfigurationDefinition(
      configurationDefinition.definitions,
      ConfigurationDefinitionsKeys.friendlyUrl
    )
    const mappedSku = friendlyUrlMap?.[path.toLowerCase()]
    if (mappedSku) {
      setResolvedSku(mappedSku)
    }
    if (resolvedPrinterNameSku && resolvedPrinterNameSku !== path) {
      setResolvedSku(resolvedPrinterNameSku)
    }
    setStatus(SkuResolverStatus.COMPLETED)
  }, [
    configurationDefinition,
    resolvedPrinterNameStatus,
    resolvedPrinterNameSku,
    path
  ])

  const resetResolverStatus = () => {
    setResolvedSku(null)
    setStatus(SkuResolverStatus.PROCESSING)
    setResolvedPrinterNameStatus(SkuResolverStatus.PROCESSING)
  }

  return {
    status,
    resolvedSku,
    resetResolverStatus
  }
}
