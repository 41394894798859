import React, { useContext, useMemo } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import classes from './FooterCopyright.module.scss'
import { ContentStackTypes } from '../store/Constants'
import { ANALYTICS } from '../store/AnalyticsConstants'
import useAnalytics from '../hooks/useAnalytics'

const FooterCopyright = () => {
  const { pageDataCache } = useContext(ConfigContext)
  const { fireHyperLinkClick } = useAnalytics(ANALYTICS.SCREENS.PRIVACY_FOOTER)
  const footerPageData = useMemo(
    () => pageDataCache?.get(ContentStackTypes.tde_layout.content_type_uid),
    [pageDataCache]
  )

  return (
    <div className={classes.footerCopyright} data-testid="footer">
      <footer data-testid="default-footer">
        <div className={classes.startArea} data-testid="footer-start-area">
          {footerPageData?.footer?.start_area?.copyright}
        </div>
        {footerPageData?.footer?.end_area && (
          <div className={classes.endArea} data-testid="footer-end-area">
            {footerPageData?.footer?.end_area?.links_group?.map(
              ({ analytics_control_name, link: { title, href } }, linkKey) => {
                return (
                  <a
                    data-testid={`footer-link-${linkKey}`}
                    key={title}
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                      fireHyperLinkClick(analytics_control_name, {
                        detail: href
                      })
                    }
                  >
                    {title}
                  </a>
                )
              }
            )}
          </div>
        )}
      </footer>
    </div>
  )
}

export default FooterCopyright
