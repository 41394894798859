import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import classes from './SidebarLayout.module.scss'
import { ConfigContext } from '../store/ConfigContext'
import IconCheckmark from '@veneer/core/dist/esm/scripts/icons/icon_checkmark'
import { ContentStackTypes, PrinterFamily } from '../store/Constants'
import { useHpPlusClient } from '../hooks/useHpPlusClient'
import { filterSubTabsByPaasAndHpPlus } from '../utils/Utils'
import SidebarLayout from './SidebarLayout'
import { useContentStack } from '@jarvis/olex-content-management'
import Sidebar123Layout from './Sidebar123Layout'

const setLabel = (label) => {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: label
      }}
    />
  )
}

const Sidebar = () => {
  const {
    header,
    states,
    footer,
    sidebar,
    paas,
    pageDataCache,
    setSelectedTab,
    setPreviousSelectedTab,
    setSubTabNavigation,
    subTabNavigation,
    printerFamily,
    printer
  } = useContext(ConfigContext)
  const [steps, setSteps] = useState([])
  const { footer: footerState, sidebar: sidebarState } = states
  const tdeLayoutPageData = useMemo(
    () => pageDataCache?.get(ContentStackTypes.tde_layout.content_type_uid),
    [pageDataCache]
  )

  const { pageData } = useContentStack({
    content_type: ContentStackTypes.sidebar_definition,
    query_modifier: (query, newQuery) => {
      let conditions = newQuery()
        .where(`taxonomies.printer_family`, printerFamily)
        .getQuery()

      return query.or(conditions)
    }
  })

  const { isHpPlus, isHpPlusCountry } = useHpPlusClient()

  const sidebarNavigation = useMemo(() => {
    const allOptionLabels = tdeLayoutPageData?.sidebar_options?.map(
      (option) => option.header
    )
    const allSubOptionLabels = new Map(
      tdeLayoutPageData?.sidebar_sub_options?.map((option) => [
        option.select,
        option.header
      ]) || []
    )
    return (
      pageData.data?.sidebar_options?.map((option) => {
        return {
          id: parseInt(option.option),
          label: allOptionLabels?.[option.option],
          sub_options: filterSubTabsByPaasAndHpPlus({
            paas,
            isHpPlus,
            isHpPlusCountry,
            subtabs: option.sub_options || []
          }).map((sub_option, sub_option_Index) => ({
            id: sub_option_Index,
            label: allSubOptionLabels.get(sub_option.sub_option),
            analytics_screen_name: sub_option.sub_option
          }))
        }
      }) || []
    )
  }, [tdeLayoutPageData, pageData.data, paas, isHpPlus])

  useEffect(() => {
    if (
      sidebarNavigation &&
      !subTabNavigation &&
      pageData.data?.sidebar_options.length > 0
    ) {
      setSubTabNavigation(sidebarNavigation)
    }
  }, [sidebarNavigation, subTabNavigation, pageData.data?.sidebar_options])

  useEffect(() => {
    if (!tdeLayoutPageData) return
    const sidebarSteps = (tdeLayoutPageData || []).sidebar

    const currentCustomNavigation = footerState.customNavigation

    if (
      sidebarSteps.length === 0 ||
      currentCustomNavigation?.keys().length === 0
    ) {
      return
    }
    const copy = new Map(sidebarSteps.map((x) => [x.identifier, x.label]))

    let customSidebar = [
      {
        label: setLabel(copy.get('paper')),
        ids: ['load-paper']
      },
      {
        label: setLabel(copy.get('ink')),
        ids: ['install-ink']
      },
      {
        label: setLabel(copy.get('alignment')),
        ids: ['alignment']
      },
      {
        label: setLabel(copy.get('hp_software')),
        ids: [
          'hp-software',
          'finish-setup-business',
          'install-driver-intranet',
          'driver-not-found-intranet',
          'start-airprint'
        ]
      }
    ]

    customSidebar = customSidebar
      .filter((step) => step)
      .map((step, index) => {
        step.step = index + 1
        return { ...step }
      })

    sidebar.setCustomSidebar(customSidebar)
  }, [footerState, tdeLayoutPageData, paas])

  useEffect(() => {
    if (!sidebarState.customSidebar && !footerState.currentRoute) return
    let stepperData = []
    let currentStepOrder
    const sidebarLength = Object.values(sidebarState.customSidebar || {}).length

    Object.values(sidebarState.customSidebar || {}).forEach((entry, index) => {
      const lastStep = entry.step === sidebarLength
      if (entry.ids.includes(footerState.currentRoute.key)) {
        currentStepOrder = entry.step
        stepperData.push({
          label: entry.label,
          step: entry.step,
          status: 'current'
        })
      } else if (
        currentStepOrder === undefined ||
        entry.step < currentStepOrder
      ) {
        stepperData.push({
          label: (
            <span
              className={classes.label}
              onClick={() => stepperClickHandler(null, index)}
            >
              {entry.label}
            </span>
          ),
          step: lastStep ? entry.step : <IconCheckmark size={26} />,
          status: lastStep ? 'current' : 'complete'
        })
      } else if (entry.step > currentStepOrder) {
        stepperData.push({
          label: entry.label,
          step: entry.step,
          status: 'incomplete'
        })
      }
    })
    setSteps(stepperData)
  }, [footerState.currentRoute, sidebarState.customSidebar])

  const stepperClickHandler = useCallback(
    (_, index) => {
      //only completed steps are allowed to be clicked
      if (steps[index].status !== 'complete') return

      const targetRouteKey = sidebarState.customSidebar[index].ids[0]
      footer.updateCurrentRoute({ key: targetRouteKey })
    },
    [steps, footer, sidebarState.customSidebar]
  )

  const onSelectOption = (id) => {
    setPreviousSelectedTab(header.selectedTab)
    setSelectedTab(id)
  }

  const onSelectSubOption = (id) => {
    header.setSelectedTabItem(header.selectedTab, id)
  }

  if (
    [
      PrinterFamily.MANHATTAN_BASE,
      PrinterFamily.MANHATTAN_HI,
      PrinterFamily.MALBEC,
      PrinterFamily.NOVELLI,
      PrinterFamily.SAYAN,
      PrinterFamily.TACCOLA
    ].includes(printerFamily)
  ) {
    const majorPicture = printer?.images
      .map((url) => ({
        url,
        size: parseInt(url?.split('/')?.pop()?.split('x')?.[0] || 0)
      }))
      .reduce((x, y) => (x?.size > y?.size ? x : y), null)

    return (
      <Sidebar123Layout
        printerImage={majorPicture?.url}
        printerName={printer?.productShortName}
      />
    )
  }

  return (
    <SidebarLayout
      sidebarNavigation={sidebarNavigation}
      onSelectOption={onSelectOption}
      onSelectSubOption={onSelectSubOption}
      selectedOption={header.selectedTab}
      selectedSubOption={header.selectedTabItem?.[header.selectedTab]}
      stepperSteps={steps}
      stepperClickHandle={stepperClickHandler}
    />
  )
}

export default Sidebar
