import React, { Fragment } from 'react'
import classes from './SidebarLayout.module.scss'
import VeneerStepper from '@veneer/core/dist/scripts/stepper'
import Button from '@veneer/core/dist/esm/scripts/button'
import clsx from 'clsx'

const SidebarLayout = ({
  sidebarNavigation,
  selectedOption,
  selectedSubOption,
  onSelectOption,
  onSelectSubOption,
  stepperSteps,
  stepperClickHandle
}) => {
  return (
    <div className={classes.stepper}>
      {sidebarNavigation.map((option) => {
        if (selectedOption === 0 && option.id === 0) {
          return (
            <Fragment key={`sidebar-option-${option.id}`}>
              <Button
                data-testid="sidebar-option-0"
                onClick={() => {
                  onSelectOption(option.id)
                }}
              >
                {option.label}
              </Button>
              <div
                className={clsx(classes.subOptions, classes.stepperBorder)}
                data-testid="sidebar-option-0-stepper"
              >
                <VeneerStepper
                  direction="vertical"
                  steps={stepperSteps}
                  interactive={!!stepperClickHandle}
                  onChange={stepperClickHandle}
                />
              </div>
            </Fragment>
          )
        } else {
          if (selectedOption > 0) {
            if (selectedOption === option.id) {
              return (
                <Fragment key={`sidebar-option-${option.id}`}>
                  <Button
                    data-testid={`sidebar-option-${option.id}`}
                    onClick={() => {
                      onSelectOption(option.id)
                    }}
                  >
                    {option.label}
                  </Button>
                  <div
                    className={classes.subOptions}
                    data-testid={`sidebar-option-${option.id}-sub-options`}
                  >
                    {option.sub_options.map((subOption) => {
                      return (
                        <Button
                          data-testid={`sidebar-option-${option.id}-sub-option-${subOption.id}`}
                          key={`sidebar-option-${option.id}-sub-option-${subOption.id}`}
                          onClick={() => {
                            onSelectSubOption(subOption.id)
                          }}
                          className={
                            selectedSubOption === subOption.id
                              ? classes.active
                              : ''
                          }
                        >
                          {subOption.label}
                        </Button>
                      )
                    })}
                  </div>
                </Fragment>
              )
            }
          }

          return (
            <Button
              data-testid={`sidebar-option-${option.id}`}
              key={`sidebar-option-${option.id}`}
              onClick={() => {
                onSelectOption(option.id)
              }}
            >
              {option.label}
            </Button>
          )
        }
      })}
    </div>
  )
}

export default SidebarLayout
