import React, { useContext, useMemo } from 'react'
import classes from './LayoutDefinition.module.scss'
import { ConfigContext } from '../store/ConfigContext'
import { ContentStackTypes, Paths } from '../store/Constants'
import Dialog from './Dialog'

/**
 * @param layout landing | onboarding | shortFlow
 * @param header {React.ReactElement}
 * @param sidebar {React.ReactElement}
 * @param children {React.ReactElement}
 * @param footer {React.ReactElement}
 * @returns {JSX.Element}
 */
export const LayoutDefinition = ({
  layout,
  header,
  sidebar,
  children,
  footer
}) => {
  const { states, isInstallRedirectURL, isWeChat, currentPath } =
    useContext(ConfigContext)

  const layoutMode = useMemo(() => {
    if (layout) {
      return layout
    }
    if (isInstallRedirectURL || !states?.sidebar?.sidebarVisible) {
      return 'landing'
    }
    return 'onboarding'
  }, [isInstallRedirectURL, states?.sidebar?.sidebarVisible, layout])

  const queryDemo = localStorage.getItem('queryDemo') === 'true'

  return (
    <div
      className={`${classes.layoutDefinition} ${
        classes[`${layoutMode}Layout`]
      }`}
      data-query-demo={queryDemo}
    >
      {isWeChat &&
        [Paths.hp_software, Paths.hp_software_trillium].includes(
          currentPath
        ) && <Dialog contentStackInfo={ContentStackTypes.wechat_dialog} />}
      <div id="side-menu-node" />
      <section className={classes.header}>{header}</section>
      <section className={classes.content}>
        <aside className={classes.sidebar}>{sidebar}</aside>
        <main className={classes.mainContent} data-testid="mfe-content">
          <div className={classes.insideMainContent}>{children}</div>
        </main>
      </section>
      {['onboarding', 'shortFlow'].includes(layoutMode) && footer}
    </div>
  )
}
