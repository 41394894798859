import axios from 'axios'
import { Stack } from '@jarvis/web-stratus-client'

class OneTwoThreeClient {
  constructor(stack) {
    this.httpClient = axios
    this.stack = stack
  }

  get123Stack() {
    let url = ''
    switch (this.stack) {
      case Stack.stage:
        url = 'https://123-stage.hpoobe.com'
        break
      case Stack.prod:
        url = 'https://123.hp.com'
        break
      default:
        url = 'https://123-test.hpoobe.com'
    }
    return url
  }

  //e.g. https://123-test.hpoobe.com/dev/api/v1/device-names/hp-designjet-t210-24-in-printer
  async getDeviceByName(printerSeoName, config = {}) {
    const apiUrl = `${this.get123Stack()}/dev/api/v1/device-names/${printerSeoName}`
    return await this.httpClient.get(apiUrl, config)
  }
}

export default OneTwoThreeClient
