import React, { createContext, useState, useEffect } from 'react'
import { FeatureFlagKeys } from './Constants'

const flagKeys = [
  {
    key: FeatureFlagKeys.navigation,
    defaultValue: {}
  },
  {
    key: FeatureFlagKeys.betaAccessCode,
    defaultValue: false
  },
  {
    key: FeatureFlagKeys.saltString,
    defaultValue: ''
  },
  {
    key: FeatureFlagKeys.landingPagePoC,
    defaultValue: []
  },
  {
    key: FeatureFlagKeys.deltaPageDerivatives,
    defaultValue: []
  },
  {
    key: FeatureFlagKeys.newLandingPage,
    defaultValue: false
  },
  {
    key: FeatureFlagKeys.hpPlusIneligibleCountries,
    defaultValue: false
  }
]

export const FeatureFlagsContext = createContext({
  flags: null
})

export const FeatureFlagsProvider = (props) => {
  const { children, featureFlags } = props

  const [ldClient, setLdClient] = useState(null)
  const [flags, setFlags] = useState(null)

  useEffect(() => {
    if (featureFlags && !ldClient) {
      featureFlags?.getClient('ld-traffic-director-client').then((client) => {
        setLdClient(client)
      })
    }
  }, [featureFlags, ldClient])

  useEffect(() => {
    const loadFlags = async () => {
      for (const flagIndex in flagKeys) {
        const keyOptions = flagKeys[flagIndex]
        const flag = await ldClient.getFeatureFlag({
          key: keyOptions.key,
          defaultValue: keyOptions.defaultValue
        })

        setFlags((prevFlags) => ({ ...prevFlags, [keyOptions.key]: flag }))
      }
    }
    if (ldClient && !flags) {
      loadFlags()
    }
  }, [ldClient, flags])

  return (
    <FeatureFlagsContext.Provider value={{ flags }}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
