import React from 'react'
import classes from './ShortFlow.module.scss'

const ShortFlow = ({ children }) => {
  return (
    <div className={classes.shortFlow} data-testid="short-flow-container">
      {children}
      <a href="#">Solve Setup Issues</a>
    </div>
  )
}

export default ShortFlow
